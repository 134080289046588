<template>
  <v-dialog :value="opened" scrollable persistent :max-width="maxWidth">
    <v-card>
      <v-card-title class="pb-1 px-1 d-flex flex-nowrap align-start">
        <v-icon x-large class="mr-3">mdi-square-edit-outline</v-icon>
        <span class="flex-grow-1 text-justify align-self-center" style="word-break: normal !important">{{ title }}</span>
        <v-btn icon @click="close()">
          <v-icon>mdi-close-circle-outline</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0 form_container">
        <v-container class="pt-0">
          <template v-if="subtitle">
            <v-alert
              dense
              style="font-size: 13px"
              text
              type="warning"
            >
              {{ subtitle }}
            </v-alert>
          </template>
          <v-row class="ml-n4 mr-0" v-if="opened">
            <template v-for="(col, k) in cols">
              <input-v
                class="pl-4"
                :key="k"
                :value="value[col.key]"
                :valid.sync="col.valid"
                :block.sync="col.block"
                :label="col"
                :opts="opts"
                :colSize="col.colSize"
                :commentForField.sync="value[col.commentForField]"
                :editable="editable(col.editable)"
                :hideInform="hideField(col.hideInform)"
                @update:block="updateBlockByFilesUploaded($event)"
                @update:files="updateBlockByFiles($event)"
                @changed="valueChanged(col.valueChanged, $event)"
                @mutate="updateValue(col.key, $event)"
                @input="updateValue(col.key, $event)"
              ></input-v>
            </template>
          </v-row>
        </v-container>
        <template v-if="errorMessage">
          <v-alert
            v-for="(error, i) of errors"
            dense
            :key="i"
            style="font-size: 13px"
            text
            type="error"
          >
            {{ error }}
          </v-alert>
        </template>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="px-6 pb-3 pt-0" v-if="opened">
        <v-spacer></v-spacer>
        <v-btn
          color="secondary"
          class="px-5"
          depressed
          @click="close(), cancel()"
        >
          {{ readOnly ? "Voltar" : "Cancelar" }}
        </v-btn>
        <v-btn
          v-if="!readOnly"
          color="primary"
          class="px-5 ml-3"
          :style="{
            background: isSaveBlocked ? '#9fa2b4 !important' : '#1C1C44 !important'
          }"
          depressed
          :dark="!isBlocked() || !isSaveBlocked"
          :disabled="isBlocked() || isSaveBlocked"
          @click="save()"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  components: {
    "input-v": () => import("@/components/input-v.vue"),
  },
  data() {
    return {
      isSaveBlocked: false,
    };
  },
  computed: {
    errors: function () {
      return Array.isArray(this.errorMessage) ? this.errorMessage : this.errorMessage ? [this.errorMessage] : [];
    },
  },
  methods: {
    isBlocked: function () {
      return this.cols
        .some((i) => !this.hideField(i.hideInform) && i.editable !== false && i.block);
    },
    isValidForm: function () {
      var valid = this.cols
        .filter((col) => !this.hideField(col.hideInform) && col.editable !== false && col.valid)
        .map(({ valid }) => !!valid())
        .every((isValid) => isValid);
      return valid;
    },
    updateBlockByFilesUploaded(data) {
      this.isSaveBlocked = data;
      return data
    },
    close: function () {
      this.$emit("close", this.cols);
      this.$emit("update:opened", false);
    },
    open: function () {
      this.$emit("update:opened", true);
    },
    updateBlockByFiles: function(data) {
      if(!data.length) {
        this.$emit("update:block", this.cols)
      }
    },
    updateValue: function (key, val) {
      const newValue = {
        ...this.value,
        [key]: val,
      };
      this.$emit("update:value", newValue);
      this.$emit("input", newValue);
      this.$emit("mutate", newValue);
    },
    hideField: function (hideInform) {
      return hideInform instanceof Function
        ? hideInform(this.value)
        : hideInform;
    },
    editable: function (editable) {
      return !this.isSend && !this.readOnly && editable != false;
    },
    valueChanged: function (valueChanged, value) {
      if (valueChanged) {
        valueChanged(value);
      }
    },
    cancel: function () {
      this.$emit("cancel");
    },
    save: function () {
      if (this.isValidForm()) {
        this.$emit("save", this.value, this.close);
      }
    },
  },
  model: {
    event: "update:opened",
    prop: "opened",
  },
  props: {
    opened: {
      type: Boolean,
      default: false,
    },
    maxWidth: {
      type: [Number, String],
      default: 500,
    },
    title: {
      type: String,
      default: "Cadastro/Edição",
    },
    cols: {
      type: Array,
      default: () => ([]),
    },
    opts: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    isSend: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    errorMessage: {
      type: [Array, String]
    },
    subtitle: {
      type: String,
      default: ''
    },
  },
};
</script>

<style lang="scss" scoped></style>
